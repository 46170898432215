<template>
  <b-card no-body class="mb-0">

    <b-card-header class="mb-1 p-1" header-bg-variant="light-primary">
      <b-media no-body>
        <b-media-aside class="mr-1">
          <b-avatar
              ref="previewEl"
              :src="(generalData.profilePictureBase64 ? generalData.profilePictureBase64 : getApiFile(generalData.profilePicture))"
              :text="avatarText(generalData.firstName + ' ' + generalData.lastName)"
              button
              variant="primary"
              size="50px"
              @click="$refs.refInputEl.click()"
              badge
              class="badge-minimal"
              badge-variant="success"/>
          <input
              ref="refInputEl"
              type="file"
              class="d-none"
              @input="inputImageRenderer">
        </b-media-aside>
        <b-media-body>
          <h5 class="mb-0">
            {{ generalData.firstName + ' ' + generalData.lastName }}
          </h5>
          <small class="text-muted">{{ generalData.email }}</small>

          <div class="my-auto">
            <b-badge variant="light-primary" v-for="role in generalData.roles" :key="role">{{ role }}</b-badge>
          </div>

        </b-media-body>
      </b-media>
    </b-card-header>

    <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
      <b-form @submit.prevent="handleSubmit(onSubmit)" enctype="multipart/form-data">
        <b-row>
          <b-col cols="12">
            <validation-provider #default="validationContext" name="İsim" rules="required">
              <b-form-group label="İsim">
                <b-form-input trim placeholder="İsim" v-model="generalData.firstName" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
          <b-col cols="12">
            <validation-provider #default="validationContext" name="Soyisim" rules="required">
              <b-form-group label="Soyisim">
                <b-form-input trim placeholder="Soyisim" v-model="generalData.lastName" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider #default="validationContext" name="Şirket Telefon">
              <b-form-group label="Şirket Telefon">
                <b-form-input trim placeholder="Şirket Telefon" v-model="generalData.phoneNumber" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider #default="validationContext" name="Kişisel Telefon">
              <b-form-group label="Kişisel Telefon">
                <b-form-input trim placeholder="Kişisel Telefon" v-model="generalData.phoneNumber2" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col cols="12">
            <validation-provider #default="validationContext" name="E-Posta" rules="required|email">
              <b-form-group label="E-Posta">
                <b-form-input trim placeholder="E-Posta" v-model="generalData.email" :state="getValidationState(validationContext)"/>
                <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <h6 class="section-label mx-1 mb-2">
            Bildirim Tercihleri
          </h6>

          <b-col cols="12" md="12" lg="12" class="mb-2">
            <b-form-checkbox class="custom-control-success mr-5" v-model="generalData.contactPreferenceEmail" switch inline>E-Mail</b-form-checkbox>
          </b-col>

          <b-col cols="12" md="12" lg="12" class="mb-2">
            <b-form-checkbox class="custom-control-success mr-5" v-model="generalData.contactPreferenceSms" switch inline>Sms</b-form-checkbox>
          </b-col>

          <b-col cols="12" md="12" lg="12" class="mb-2">
            <b-form-checkbox class="custom-control-success mr-5" v-model="generalData.contactPreferencePushNotification" switch inline>Mobil</b-form-checkbox>
          </b-col>

        </b-row>
        <action-buttons :back-route="'home'"/>
      </b-form>
    </validation-observer>
    <Overlay :busy="busy"></Overlay>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BAlert, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg, BFormInvalidFeedback, BAvatar, BCardHeader, BCardTitle, BBadge, BFormCheckbox,
} from 'bootstrap-vue'
import {ValidationProvider, ValidationObserver} from 'vee-validate'
import {useInputImageRenderer} from '@core/comp-functions/forms/form-utils'
import {onUnmounted, ref} from '@vue/composition-api'
import {email, required} from "@core/utils/validations/validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import {avatarText, getApiFile} from "@core/utils/filter"
import ActionButtons from "@/components/Form/ActionButtons.vue";
import Overlay from "@/components/Overlay.vue";
import {useToast} from "vue-toastification/composition";
import store from "@/store";
import storeModule from "@/views/common/account/store";
import {toastMessage} from "@core/utils/utils";
import {$themeConfig} from "@themeConfig";

export default {
  components: {
    BFormCheckbox,
    Overlay,
    ActionButtons,
    BBadge,
    BCardTitle,
    BCardHeader,
    BAvatar,
    BFormInvalidFeedback,
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BAlert,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,

    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      required,
      email,
    }
  },
  props: {
    generalData: {
      type: Object,
      default: () => {
      },
    },
  },
  setup(props) {
    const STORE_MODULE_NAME = 'store'
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const previewEl = ref(null)
    const refInputEl = ref(null)
    const {inputImageRenderer} = useInputImageRenderer(refInputEl, base64 => {
      props.generalData.profilePictureBase64 = base64
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation()

    const toast = useToast()
    const busy = ref(false)

    const onSubmit = () => {
      if (!$themeConfig.app.appIsDemo) {
        if ((props.generalData.phoneNumber === null || props.generalData.phoneNumber === '') && (props.generalData.phoneNumber2 === null || props.generalData.phoneNumber2 === '')) {
          toastMessage(toast, 'danger', 'Şirket telefonu veya Kişisel telefon bilgisi boş geçilemez!')
          return
        }

        busy.value = true
        store.dispatch('store/editItem', props.generalData).then(response => {
          const user = JSON.parse(localStorage.getItem('userData'))
          user.email = response.data.data.email
          user.phoneNumber = response.data.data.phoneNumber
          user.name = response.data.data.firstName + ' ' + response.data.data.lastName
          user.profilePicture = response.data.data.profilePicture
          localStorage.setItem('userData', JSON.stringify(user))
          toastMessage(toast, 'success', response.data.message)
          setTimeout(function () {
            window.location.reload()
          }, 2000);
        }).catch(error => {
          toastMessage(toast, 'danger', error.response.data.message)
        }).finally(message => {
          busy.value = false
        })
      } else {
        toastMessage(toast, 'danger', 'Demo kullanımda işlem yapılamaz!')
      }
    }

    return {
      refFormObserver,
      previewEl,
      refInputEl,
      busy,

      onSubmit,
      getApiFile,
      avatarText,
      getValidationState,
      resetForm,
      inputImageRenderer,
    }
  },
}
</script>
